@import "settings/settings";
/**
 * Foundation for Sites by ZURB
 * Version 6.0.5
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Sass utilities
@import 'util/util';

// Global variables and styles
@import 'global';

// Components
@import 'grid/grid';


@import 'components/visibility';












@import 'components/flex-video';


















@mixin foundation-everything {
  @include foundation-global-styles;
  @include foundation-grid;
  
  
  
  @include foundation-visibility-classes;
  
  
  
  
  
  
  
  
  
  
  
  @include foundation-flex-video;
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
}



